import React from 'react';

import styled from 'styled-components';

import { BasicText } from '../../styled-subcomponents/BasicText';
import { DateInput, formatDate } from 'lib/DateInput';
import { Input } from 'lib/Input';
import { SubTitle } from '../../styled-subcomponents/SubTitle';

const Section = styled.div`
	margin-top: 32px;
`;

interface LeasePeriodProps {
	preferredLeasePeriodMonths: number;
	leasePeriodMonths: number;
	showValidationErrors: boolean;
	preferredMoveInDate: string;
	moveInDate: string;
	remainingBookingSlots?: number;
	onDateChange(date: string): void;
	onLeasePeriodChange(months: number): void;
	onDateElementClick(): void;
}

export const LeasePeriod = ({
	preferredLeasePeriodMonths,
	leasePeriodMonths,
	showValidationErrors,
	preferredMoveInDate,
	moveInDate,
	onDateChange,
	onLeasePeriodChange,
	onDateElementClick,
}: LeasePeriodProps) => {
	const handleDateSelect = (date?: Date) => {
		if (!date) {
			return;
		}
		onDateChange(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`);
	};

	return (
		<>
			<Section onClick={onDateElementClick}>
				<SubTitle>Lease period</SubTitle>
				<DateInput
					value={moveInDate}
					placeholder={'When is your preferred move-in date?'}
					onChange={handleDateSelect}
					isErrorState={showValidationErrors && !moveInDate}
				/>
				<BasicText>
					This landlord has requested a move-in date of {formatDate(preferredMoveInDate)}
				</BasicText>
			</Section>

			<Section>
				<Input
					type={'number'}
					placeholder={'How long would you like to stay?'}
					value={leasePeriodMonths || ''}
					suffix={'Months'}
					onChange={onLeasePeriodChange}
					isErrorState={showValidationErrors && !leasePeriodMonths}
				/>
				<BasicText>
					This landlord prefers a lease period of {preferredLeasePeriodMonths} months
				</BasicText>
			</Section>
		</>
	);
};
